import React from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { GuildIconSingle } from '../Icons/GuildIconSingle'
import { EyebrowMD, ParagraphSM, TitleXS } from '../Text'
import { GuildTooltip } from './GuildTooltip'

interface GuildScoreProps {
  guildScore?: number | null
  iconSize?: number
}

export const GuildScore: React.FC<GuildScoreProps> = ({ guildScore, iconSize = 24 }) => {
  const { t } = useTranslate('common')

  if (guildScore == null || guildScore < 70) return null

  const trigger = (
    <>
      <div className="flex flex-row items-center gap-1">
        <GuildIconSingle size={iconSize} color={'warning-500'} animateColor={'core-gray-400'} />
        <EyebrowMD color="white">{guildScore}</EyebrowMD>
      </div>
    </>
  )

  const content = (
    <>
      <TitleXS color="core-gray-950" weight="bold">
        {t('whatIsGuildScore', 'What is the Angel Guild score?')}
      </TitleXS>
      <ParagraphSM className="mt-2 text-[0.688rem] leading-[16px]" color="core-gray-700">
        <EyebrowMD className="text-pretty normal-case tracking-tight">
          {t(
            'guildScoreDescription',
            'Every film and series on Angel Studios is first voted on by members of the Angel Guild. The higher the Guild score, the more passionate the Guild is about the film or series coming to Angel Studios.',
          )}
        </EyebrowMD>
      </ParagraphSM>
    </>
  )

  return (
    <GuildTooltip
      className={''}
      buttonClassName={''}
      panelClassName={''}
      panelPosition={'CENTER'}
      isIconOnly={false}
      removeBorder={true}
      buttonStyle={{ padding: 0 }}
      trigger={trigger}
      content={content}
      guildScore={guildScore}
    />
  )
}
