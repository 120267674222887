import React, { FC } from 'react'
import { gql, useQuery } from '@apollo/client'
import classNames from 'classnames'
import LazyHydrate from 'react-lazy-hydration'
import CarouselNav from '@/atoms/CarouselNav'
import { PlayCircleIcon } from '@/atoms/Icons/PlayCircleIcon'
import { AsH1, CaptionSM, TitleLG, TitleMD } from '@/atoms/Text'
import { paths } from '@/constants'
import { PlaceholderMediaCard } from '@/molecules/MediaCard'
import { PortraitTileCard } from '@/molecules/PortraitRowCollection'
import { useGuildUser } from '@/services/GuildUserService'
import { LinkViewModel } from '@/services/RenderService'
import { GetGuildUpdatesQuery } from '@/types/codegen-federation'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate, Translate } from '@/utils/translate/translate-client'
import useCarousel from '@/utils/useCarousel'

interface Props {
  testId?: string
  title?: string
  paddingClassName?: string
}

const GET_GUILD_UPDATES_QUERY = gql`
  query getGuildUpdates {
    guildUpdates {
      id
      posterCloudinaryPath
      projectName
      title
      videoAspectRatio
      videoUrl
    }
  }
`

export const InnerGuildUpdatesPortraitRowCollection: FC<Props> = ({ testId, title, paddingClassName }) => {
  const [carouselRef, { canScrollBack, canScrollForward, scrollBack, scrollForward }] = useCarousel<HTMLDivElement>()
  const track = useSafeTrack()
  const { t } = useTranslate('watch')
  const { data, loading: updatesLoading } = useQuery<GetGuildUpdatesQuery>(GET_GUILD_UPDATES_QUERY, {
    errorPolicy: 'all',
  })

  return (
    <div className={classNames('relative')} data-testid={testId}>
      {/* Title matches styles used on /watch page */}
      {title && (
        <header className={paddingClassName}>
          <TitleLG weight="bold" as={AsH1}>
            {title}
          </TitleLG>
        </header>
      )}
      <div
        ref={carouselRef}
        className={classNames(
          'flex snap-x snap-mandatory scroll-px-4 gap-4 overflow-auto overscroll-x-none pt-4 scrollbar-none *:w-[200px] *:shrink-0 *:snap-center xl:pr-6',
          paddingClassName,
        )}
      >
        <div>
          <div className="relative mb-2 overflow-hidden rounded-lg bg-cover">
            <div className="relative w-full rounded-lg bg-core-gray-900">
              <div className="aspect-h-16 aspect-w-10 relative w-full">
                <div className="absolute top-1/2 mx-14 h-fit w-fit -translate-y-1/2 sm:mx-2 md:mx-4 lg:mx-8 xl:mx-4">
                  <TitleMD color="white" weight="semibold" className="sm:photon-title-sm md:photon-title-md">
                    <Translate i18nKey="yourGuildParticipationMakesADifference" t={t}>
                      Your Guild participation makes a difference.
                    </Translate>
                  </TitleMD>
                  <TitleMD color="oxide-bright" weight="semibold" className="sm:photon-title-sm md:photon-title-md">
                    <Translate i18nKey="thankYou." t={t}>
                      Thank You.
                    </Translate>
                  </TitleMD>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!updatesLoading &&
          data?.guildUpdates?.map((item) => {
            if (!item) return null
            const { projectName, title, videoUrl, posterCloudinaryPath, id } = item

            if (!title || !posterCloudinaryPath || !videoUrl) return null

            const linkModel: LinkViewModel = {
              name: title,
              imageUrl: posterCloudinaryPath,
              alt: title,
              linkUrl: `${paths.guild.updates}/${id}`,
              label: title,
              track: {
                eventName: 'Guild Updates Thumbnail Clicked',
                payload: {
                  id,
                  projectName,
                },
              },
            }

            const handleTracking = () => {
              track(linkModel.track!.eventName, linkModel.track?.payload)
            }

            return (
              <PortraitTileCard key={id} className="mb-2" linkModel={linkModel} onClick={handleTracking}>
                <div className="absolute inset-0">
                  <div className="pointer-events-none absolute left-2 top-2 rounded-xl bg-black/80 px-2 py-1">
                    <CaptionSM color="white">{projectName}</CaptionSM>
                  </div>
                  <div className="flex h-full w-full items-center justify-center opacity-0 duration-200 hover:bg-[#00000088] hover:opacity-100">
                    <div className="mx-auto h-fit w-fit !opacity-100">
                      <PlayCircleIcon />
                    </div>
                  </div>
                </div>
              </PortraitTileCard>
            )
          })}
        {updatesLoading && (
          <div className="mb-1 flex h-full w-full">
            {Array(5)
              .fill(0)
              .map((v, i) => (
                <PlaceholderMediaCard
                  className="mr-4 w-[200px] shrink-0"
                  borderRadiusClassName="rounded-lg"
                  hideTextPlaceholders
                  orientation="portrait"
                  key={i}
                />
              ))}
          </div>
        )}
      </div>

      <CarouselNav
        active={canScrollBack}
        direction="back"
        onClick={scrollBack}
        orientation="horizontal"
        className="z-10"
      />
      <CarouselNav
        active={canScrollForward}
        direction="forward"
        onClick={scrollForward}
        orientation="horizontal"
        className="z-10"
      />
    </div>
  )
}

export const GuildUpdatesPortraitRowCollection: FC<Props> = (props) => {
  const { isGuildMember } = useGuildUser()

  if (!isGuildMember) return null

  return (
    /* We get Guild Updates client side so handle hydration */
    <LazyHydrate as="Fragment" whenVisible>
      {/* Inner component needed for useCarousel */}
      <InnerGuildUpdatesPortraitRowCollection {...props} />
    </LazyHydrate>
  )
}
