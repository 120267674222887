import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { Image } from '@/atoms/Image'
import { InternalLink } from '@/atoms/InternalLink'
import { LinkViewModel } from '@/services/RenderService'
import { ReactFCC } from '@/types/react'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'

interface Props extends React.HTMLAttributes<HTMLElement> {
  linkModel: LinkViewModel
  onClick?: () => void
  defaultLandscape?: boolean
}

export const LandscapeTileCard: ReactFCC<Props> = ({
  children,
  className,
  linkModel,
  onClick,
  defaultLandscape = false,
  ...props
}) => {
  const [isLoadingImage, setLoadingImage] = useState(true)

  const onLoadComplete = useCallback(() => setLoadingImage(false), [])

  return (
    <div {...props} onClick={onClick}>
      <InternalLink href={linkModel.linkUrl}>
        <div
          className={classNames(
            'relative cursor-pointer overflow-hidden rounded-lg bg-cover duration-[400ms] ease-in-out hover:z-10 md:hover:scale-[1.05]',
            className,
          )}
        >
          <div className={classNames(['relative w-full bg-black rounded-lg', isLoadingImage ? 'animate-pulse' : ''])}>
            <div className={classNames('w-full relative aspect-w-16 aspect-h-9')}>
              {defaultLandscape
                ? linkModel?.landscapeUrl && (
                    <Image
                      src={getCloudinaryImageUrl({ path: linkModel.landscapeUrl ?? '', width: 650, height: 366 })}
                      className={classNames(['object-cover rounded-lg absolute top-0 left-0 h-full w-full'])}
                      alt={linkModel.alt as string}
                      onLoadingComplete={onLoadComplete}
                      width={450}
                      height={253}
                    />
                  )
                : linkModel.imageUrl && (
                    <Image
                      src={getCloudinaryImageUrl({ path: linkModel.imageUrl ?? '', width: 650, height: 366 })}
                      className={classNames(['object-cover rounded-lg absolute top-0 left-0 h-full w-full'])}
                      alt={linkModel.alt as string}
                      onLoadingComplete={onLoadComplete}
                      width={450}
                      height={253}
                    />
                  )}
            </div>
            {children}
          </div>
        </div>
      </InternalLink>
    </div>
  )
}
