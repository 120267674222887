import React from 'react'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { GuildAccessPill } from 'src/molecules/GuildAccessPill'
import { PlayButtonThumbnailOverlay } from '@/atoms/PlayButtonThumbnailOverlay'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { paths } from '@/constants'
import { GuildAccessNotch } from '@/molecules/GuildAccessNotch'
import { LandscapeRowCollection, LandscapeTileCard } from '@/molecules/LandscapeRowCollection'
import { ProgressOverlay } from '@/molecules/MediaCard/ProgressOverlay'
import { PlaceholderLandscapeCollection } from '@/organisms/PlaceholderLandscapeCollection'
import {
  ComingSoonEpisode,
  ComingSoonNode,
  ComingSoonSpecial,
  getComingSoonTab,
  isComingSoonEpisode,
  isComingSoonSpecial,
} from '@/services/ComingSoonService'
import { firstUsableLandscapePath } from '@/services/ComingSoonService/ComingSoonService'
import { useGuildUser } from '@/services/GuildUserService'
import { mapFreeAccessStart, mapGuildAccessStart } from '@/services/PhaseManagerService/PhaseManagerService'
import { ProjectType } from '@/services/ProjectsService'
import { LinkViewModel } from '@/services/RenderService'
import { buildEpisodeTitle, buildLinkToEpisode } from '@/utils/EpisodeUtil'
import { AnalyticsEvent, useSafeTrack } from '@/utils/analytics'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import { isDefined } from '@/utils/types'

type FilteredProjectType = ComingSoonEpisode | ComingSoonSpecial
function isFilteredProjectType(node: ComingSoonNode): node is FilteredProjectType {
  return isComingSoonEpisode(node) || isComingSoonSpecial(node)
}

export function UpcomingEpisodesSpecialsCollection({
  testId,
  title,
}: {
  title: string
  testId?: string
  enableWatchProgress?: boolean
}) {
  const track = useSafeTrack()
  const { t } = useTranslate('watch')
  const { isGuildMember } = useGuildUser()

  const [loading, setLoading] = React.useState(true)
  const [nodes, setNodes] = React.useState<(ComingSoonSpecial | ComingSoonEpisode)[]>([])
  React.useEffect(() => {
    ;(async () => {
      setLoading(true)
      const newNodes = await getComingSoonTab()
      setNodes(newNodes.filter(isDefined).filter(isFilteredProjectType))
      setLoading(false)
    })()
  }, [])

  if (loading) {
    return <PlaceholderLandscapeCollection />
  }

  return (
    <LandscapeRowCollection testId={testId} title={title}>
      {nodes?.map((node) => {
        const linkModel = buildLinkModel(node)
        if (!linkModel) return null

        const duration = node.watchable.duration
        const primaryFlowPhases =
          node?.project.primaryFlowPhases?.filter(isDefined).map((flowPhase) => {
            return {
              ...flowPhase,
              releaseWindows: flowPhase.releaseWindows?.filter(isDefined) ?? [],
            }
          }) ?? []
        const earlyAccessDate = mapGuildAccessStart(primaryFlowPhases)

        const progress = ((node.watchable.watchPosition?.position ?? 0) / (node.watchable.duration ?? 0)) * 100
        const unavailableReason = mapUnavailableReason(node)

        return (
          <LazyHydrate key={node?.id} whenVisible>
            <>
              <LandscapeTileCard
                className="mb-2 mr-4"
                linkModel={linkModel}
                onClick={() => {
                  track('Upcoming Episodes & Specials Thumbnail Clicked', {
                    id: node.id,
                    projectSlug: node.project.slug,
                  })
                }}
              >
                <When condition={Boolean(progress)}>
                  <ProgressOverlay progress={progress} />
                </When>
                <div className="absolute inset-0">
                  <GuildAccessPill
                    isGuildMember={isGuildMember}
                    earlyAccessDate={earlyAccessDate}
                    unavailableReason={unavailableReason}
                  />
                  {!!earlyAccessDate && (
                    <GuildAccessNotch
                      earlyAccessDate={earlyAccessDate}
                      publiclyAvailableDate={mapFreeAccessStart(primaryFlowPhases)}
                      isGuildMember={isGuildMember}
                    />
                  )}
                </div>
                {!unavailableReason && <PlayButtonThumbnailOverlay />}
              </LandscapeTileCard>
              <TitleAndSubtitleDisplay
                className="mr-4"
                title={formatEyebrow(node, t) ?? undefined}
                subtitle={formatSubtitle(node)}
                duration={duration}
              />
            </>
          </LazyHydrate>
        )
      })}
    </LandscapeRowCollection>
  )
}

// TODO: there is so much more going on here than is actually used. LinkViewModel is heavy,
// and LandscapeTileCard doesn't need it. Refactor to make this mostly unneeded.
function buildLinkModel(node: ComingSoonNode): LinkViewModel | null {
  if (!node?.project.slug) return null

  const projectSlug = node.project.slug
  const episodeNumber = isComingSoonEpisode(node) ? node.watchable.episodeNumber : 1
  const guid = node.id
  const seasonNumber = isComingSoonEpisode(node) ? node.watchable.season.seasonNumber : 1

  const linkUrl =
    buildLinkToEpisode({
      projectSlug,
      episodeNumber,
      guid,
      seasonNumber,
      subtitle: node.subtitle ?? '',
    }) ?? `/${paths.watch.index}/${node.project.slug}`

  const title = buildEpisodeTitle({
    title: node.subtitle as string,
    projectType: mapProjectType(node),
    episodeNumber,
    seasonNumber,
  })

  return {
    alt: title ? `Link to show ${title}` : 'Link to content',
    label: title,
    imageUrl: firstUsableLandscapePath(node.watchable),
    linkUrl,
    track: {
      eventName: 'Content Link Clicked' as AnalyticsEvent,
      payload: {
        linkUrl,
        projectSlug,
        episodeNumber,
        guid,
        seasonNumber,
      },
    },
  }
}

function mapProjectType(node: ComingSoonNode): ProjectType {
  switch (node?.watchable.__typename) {
    case 'ContentEpisode':
    case 'ContentSeries':
      return 'series'
    default:
      return 'movie'
  }
}

function formatEyebrow(node: FilteredProjectType, t: TranslateFunction) {
  if (!node) return null

  if (node.watchable.name?.toLowerCase() === 'minisode') {
    return t('minisode', 'Minisode')
  } else if (isComingSoonEpisode(node)) {
    return t('seasonEpisodeNumber', 'S{{seasonNumber}}:E{{episodeNumber}} {{projectName}}', {
      episodeNumber: node.watchable.episodeNumber,
      seasonNumber: node.watchable.season.seasonNumber,
      projectName: formatProjectTitle(node),
    })
  } else if (isComingSoonSpecial(node)) {
    return t('special', 'Special')
  } else if ('movie' === mapProjectType(node)) {
    return t('film', 'Film')
  } else {
    return null
  }
}

function formatSubtitle(node: FilteredProjectType) {
  return isComingSoonEpisode(node)
    ? formatEpisodeTitle(node)
    : isComingSoonSpecial(node)
    ? formatProjectTitle(node)
    : undefined
}

function formatProjectTitle(node: FilteredProjectType) {
  return isComingSoonEpisode(node)
    ? node.watchable.project.name
    : isComingSoonSpecial(node)
    ? node.watchable.name
    : undefined
}

function formatEpisodeTitle(node: ComingSoonEpisode) {
  return node.watchable.name ?? node.watchable.subtitle
}

function mapUnavailableReason(node: FilteredProjectType) {
  const status = node.watchable.watchableAvailabilityStatus
  const AVAILABLE_STATUSES = ['WATCHABLE', 'WATCHABLE_AT']
  return !status || AVAILABLE_STATUSES.includes(status) ? undefined : status
}
