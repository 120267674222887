import React, { useCallback } from 'react'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { ResponsiveObject } from 'react-slick'
import { Placeholder } from '@/atoms/Placeholder'
import { AsH1, TitleLG } from '@/atoms/Text'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { HorizontalItemScroller } from '@/molecules/HorizontalItemScroller'
import { LinkViewModel } from '@/services/RenderService'
import { createPlaceholderArray } from '@/utils/PlaceholderArrays'
import { useSafeTrack } from '@/utils/analytics'
import { LandscapeTileCard } from '../LandscapeRowCollection'
import { PortraitTileCard } from '../PortraitRowCollection'

export interface Props {
  title?: string
  contentLinks?: LinkViewModel[]
  isGuildMember?: boolean
}

const PLACEHOLDERS = createPlaceholderArray(8)

export const MoviesAndSeriesCollection: React.FC<Props> = React.memo(
  ({ contentLinks, title, isGuildMember = false }) => {
    const track = useSafeTrack()
    const sorted = contentLinks?.sort((a, b) => (a?.sortOrder || 0) - (b?.sortOrder || 0)) || []

    const responsiveSlick: ResponsiveObject[] = [
      {
        breakpoint: 1536,
        settings: {
          slidesToScroll: 6,
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToScroll: 5,
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 4,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
    ]

    const trackClick = useCallback(
      (contentLink: LinkViewModel) => {
        if (!contentLink.track) return
        track(contentLink.track?.eventName, contentLink.track?.payload)
      },
      [track],
    )
    return (
      <div data-testid="movies-and-series-collection">
        {title && (
          <header>
            <TitleLG weight="bold" as={AsH1} data-testid="project-tiles-header" className="mb-3 md:mb-4">
              {title}
            </TitleLG>
          </header>
        )}
        <When condition={isGuildMember}>
          <LazyHydrate whenVisible>
            <GBFadeInExperiment defaultValue={false} experimentName="movies-and-series-portrait-landscape-guild">
              <ExperimentVariation variation={true}>
                <HorizontalItemScroller slidesToScroll={6} slidesToShow={6} responsive={responsiveSlick}>
                  {sorted.length === 0 &&
                    PLACEHOLDERS.map((v) => {
                      return (
                        <div key={v} className="pr-4">
                          <Placeholder className="aspect-h-2 aspect-w-3 rounded-lg" />
                        </div>
                      )
                    })}

                  {contentLinks
                    ?.filter((contentLink) => contentLink?.imageUrl)
                    ?.map((contentLink) => {
                      if (!contentLink?.portraitUrl) return null

                      return (
                        <PortraitTileCard
                          className="mb-2 mr-4"
                          key={contentLink.imageUrl}
                          linkModel={contentLink}
                          onClick={() => trackClick(contentLink)}
                          defaultPortrait
                        />
                      )
                    })}
                </HorizontalItemScroller>
              </ExperimentVariation>
              <ExperimentVariation variation={false}>
                <HorizontalItemScroller>
                  {sorted.length === 0 &&
                    PLACEHOLDERS.map((v) => {
                      return (
                        <div key={v} className="pr-4">
                          <Placeholder className="aspect-h-9 aspect-w-16 rounded-lg" />
                        </div>
                      )
                    })}

                  {contentLinks
                    ?.filter((contentLink) => contentLink?.imageUrl)
                    ?.map((contentLink) => {
                      if (!contentLink?.landscapeUrl) return null

                      return (
                        <LandscapeTileCard
                          className="mb-2 mr-4"
                          key={contentLink.imageUrl}
                          linkModel={contentLink}
                          onClick={() => trackClick(contentLink)}
                          defaultLandscape
                        />
                      )
                    })}
                </HorizontalItemScroller>
              </ExperimentVariation>
              <ExperimentVariation default>
                <HorizontalItemScroller>
                  {sorted.length === 0 &&
                    PLACEHOLDERS.map((v) => {
                      return (
                        <div key={v} className="pr-4">
                          <Placeholder className="aspect-h-9 aspect-w-16 rounded-lg" />
                        </div>
                      )
                    })}

                  {contentLinks
                    ?.filter((contentLink) => contentLink?.imageUrl)
                    ?.map((contentLink) => {
                      if (!contentLink?.landscapeUrl) return null

                      return (
                        <LandscapeTileCard
                          className="mb-2 mr-4"
                          key={contentLink.imageUrl}
                          linkModel={contentLink}
                          onClick={() => trackClick(contentLink)}
                          defaultLandscape
                        />
                      )
                    })}
                </HorizontalItemScroller>
              </ExperimentVariation>
            </GBFadeInExperiment>
          </LazyHydrate>
        </When>
        <When condition={!isGuildMember}>
          <LazyHydrate whenVisible>
            <GBFadeInExperiment defaultValue={false} experimentName="movies-and-series-portrait-landscape">
              <ExperimentVariation variation={true}>
                <HorizontalItemScroller slidesToScroll={6} slidesToShow={6} responsive={responsiveSlick}>
                  {sorted.length === 0 &&
                    PLACEHOLDERS.map((v) => {
                      return (
                        <div key={v} className="pr-4">
                          <Placeholder className="aspect-h-2 aspect-w-3 rounded-lg" />
                        </div>
                      )
                    })}

                  {contentLinks
                    ?.filter((contentLink) => contentLink?.imageUrl)
                    ?.map((contentLink) => {
                      if (!contentLink?.portraitUrl) return null

                      return (
                        <PortraitTileCard
                          className="mb-2 mr-4"
                          key={contentLink.imageUrl}
                          linkModel={contentLink}
                          onClick={() => trackClick(contentLink)}
                          defaultPortrait
                        />
                      )
                    })}
                </HorizontalItemScroller>
              </ExperimentVariation>
              <ExperimentVariation variation={false}>
                <HorizontalItemScroller>
                  {sorted.length === 0 &&
                    PLACEHOLDERS.map((v) => {
                      return (
                        <div key={v} className="pr-4">
                          <Placeholder className="aspect-h-9 aspect-w-16 rounded-lg" />
                        </div>
                      )
                    })}

                  {contentLinks
                    ?.filter((contentLink) => contentLink?.imageUrl)
                    ?.map((contentLink) => {
                      if (!contentLink?.landscapeUrl) return null

                      return (
                        <LandscapeTileCard
                          className="mb-2 mr-4"
                          key={contentLink.imageUrl}
                          linkModel={contentLink}
                          onClick={() => trackClick(contentLink)}
                          defaultLandscape
                        />
                      )
                    })}
                </HorizontalItemScroller>
              </ExperimentVariation>
              <ExperimentVariation default>
                <HorizontalItemScroller>
                  {sorted.length === 0 &&
                    PLACEHOLDERS.map((v) => {
                      return (
                        <div key={v} className="pr-4">
                          <Placeholder className="aspect-h-9 aspect-w-16 rounded-lg" />
                        </div>
                      )
                    })}

                  {contentLinks
                    ?.filter((contentLink) => contentLink?.imageUrl)
                    ?.map((contentLink) => {
                      if (!contentLink?.landscapeUrl) return null

                      return (
                        <LandscapeTileCard
                          className="mb-2 mr-4"
                          key={contentLink.imageUrl}
                          linkModel={contentLink}
                          onClick={() => trackClick(contentLink)}
                          defaultLandscape
                        />
                      )
                    })}
                </HorizontalItemScroller>
              </ExperimentVariation>
            </GBFadeInExperiment>
          </LazyHydrate>
        </When>
      </div>
    )
  },
)

MoviesAndSeriesCollection.displayName = 'React.memo(MoviesAndSeriesCollection)'
