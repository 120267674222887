import React, { useRef } from 'react'
import styles from './HorizontalBannerScroller.module.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import classNames from 'classnames'
import Slider, { Settings } from 'react-slick'
import { ChevronLeftIcon } from '@/atoms/Icons/ChevronLeftIcon'
import { ChevronRightIcon } from '@/atoms/Icons/ChevronRightIcon'
import { MediaCardProps } from '@/molecules/MediaCard/MediaCard'

export interface KeyedMediaCardProps extends MediaCardProps {
  key: string
  guid: string
}

interface ReactSlickArrowProps {
  currentSlide?: number
  slideCount?: number
  onClick?: () => void
  style?: Record<string, string | number>
}

const getChevronClassNames = (visible: boolean, className?: string) =>
  classNames(
    'cursor-pointer max-w-12 min-w-12',
    'transition-opacity opacity-0',
    'h-full w-6 sm:w-8 md:w-12 xl:w-16',
    'z-10 absolute top-0',
    '!flex items-center justify-center',
    visible ? 'hover:!opacity-100' : '!hidden',
    className,
  )

export const HorizontalBannerScroller: React.FC<Settings & { scrollerClassName?: string }> = ({
  children,
  scrollerClassName,
  ...props
}) => {
  const slider = useRef<Slider>(null)

  const ReactSlickChevronRight: React.FC<ReactSlickArrowProps> = (props) => {
    const { onClick, style } = props

    return (
      <div
        data-testid="horizontal-banner-scroller-right-arrow"
        style={style}
        className={getChevronClassNames(true, 'right-0 translate-x-full')}
        onClick={onClick}
      >
        <div className="flex items-center justify-items-center rounded-full bg-black/25 p-2.5  ">
          <ChevronRightIcon className="pl-1" height={28} width={28} />
        </div>
      </div>
    )
  }

  const ReactSlickChevronLeft: React.FC<ReactSlickArrowProps> = (props) => {
    const { onClick, style } = props

    return (
      <div style={style} className={getChevronClassNames(true, 'left-0 -translate-x-full')} onClick={onClick}>
        <div className="flex items-center justify-items-center rounded-full bg-black/25 p-2.5">
          <ChevronLeftIcon className="pr-1" height={28} width={28} />
        </div>
      </div>
    )
  }

  /* The outer div with overflow-hidden and inner div with the mix of padding and margins fixes an overscroll issue with iOS Safari */
  return (
    <div className={styles.horizontalBannerScroller}>
      <div className="-pr-12 -ml-12 pl-12 lg:m-0 lg:p-0 ">
        <Slider
          className={scrollerClassName}
          swipe
          touchMove
          useCSS
          useTransform
          waitForAnimate
          cssEase="ease"
          easing="easeQuadOut"
          lazyLoad={undefined}
          ref={slider}
          initialSlide={0}
          swipeToSlide={false}
          touchThreshold={5}
          edgeFriction={0.35}
          speed={300}
          autoplaySpeed={8000}
          autoplay
          dots
          infinite
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<ReactSlickChevronRight onClick={slider?.current?.slickNext} />}
          prevArrow={<ReactSlickChevronLeft onClick={slider?.current?.slickPrev} />}
          {...props}
        >
          {children}
        </Slider>
      </div>
    </div>
  )
}
