import { ApolloClient, gql } from '@apollo/client'
import locales from '@/constants/locales'
import { ContentfulClientOptions, getWebClient } from '@/services/ApolloClient'
import {
  getLivestreamByIdProps,
  Livestream,
  LivestreamDataStructure,
  LivestreamMeta,
} from '@/services/LivestreamService/types'
import { getProjectMeta, ProjectMeta } from '@/services/ProjectsService'
import { GetAllProjectsAndLivestreamsQuery } from '@/types/codegen-federation'
import { isDefined } from '@/utils/types'

export const GET_LIVESTREAM_META_DATA = gql`
  query getStreamById($id: ID!) {
    livestream(id: $id) {
      bannerCloudinaryPath
      description
      durationSeconds
      endedAt
      guid
      isArchived
      isEnded
      live
      name
      projectSlug
      startAt
      url
      whitelistedGroups {
        name
      }
      unavailableReason
    }
  }
`

export const GET_LIVESTREAM_META_DATA_BY_GUID = gql`
  query getStreamByGuid($guid: String!) {
    livestreamByGuid(guid: $guid) {
      bannerCloudinaryPath
      description
      durationSeconds
      endedAt
      guid
      isArchived
      isEnded
      live
      name
      projectSlug
      startAt
      url
      unavailableReason
      whitelistedGroups {
        name
      }
    }
  }
`

export const GET_PLAYABLE_LIVESTREAMS_BY_PROJECT_QUERY = gql`
  query getPlayableLivestreamsByProject($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
      livestreams(includeGuildOnly: true) {
        projectSlug
        name
        bannerCloudinaryPath
        id
        guid
        live
        isEnded
        startAt
        url
        description
        unavailableReason
      }
    }
  }
`

export const GET_ALL_PROJECTS_AND_LIVESTREAMS = gql`
  query getAllProjectsAndLivestreams {
    projects {
      livestreams(includeGuildOnly: true) {
        bannerCloudinaryPath
        guid
        id
        isEnded
        live
        name
        projectSlug
        startAt
        unavailableReason
      }
    }
  }
`

export async function getLivestreamMetaData(
  { id, guid }: getLivestreamByIdProps,
  client: ApolloClient<object>,
  opts: ContentfulClientOptions,
): Promise<{
  livestreamMeta: LivestreamMeta
  projectMeta: ProjectMeta
}> {
  const variables = guid ? { guid } : { id }
  const { data } = await client.query({
    query: guid ? GET_LIVESTREAM_META_DATA_BY_GUID : GET_LIVESTREAM_META_DATA,
    variables,
    errorPolicy: 'all',
  })

  const livestream = data?.livestreamByGuid ?? data?.livestream

  if (!livestream) {
    throw new Error('Livestream Not Found')
  }

  let projectMeta

  if (livestream.projectSlug) {
    projectMeta = await getProjectMeta({ slug: livestream.projectSlug }, client, opts)
  }

  if (!projectMeta) {
    throw new Error('Project Not Found')
  }

  return {
    livestreamMeta: livestream,
    projectMeta,
  }
}

interface GetPlayableLivestreamsByProjectProps {
  projectSlug: string
}

export const getPlayableLivestreamsByProject = async (
  { projectSlug }: GetPlayableLivestreamsByProjectProps,
  client: ApolloClient<object>,
): Promise<LivestreamDataStructure | null> => {
  const { data } = await client.query({
    query: GET_PLAYABLE_LIVESTREAMS_BY_PROJECT_QUERY,
    variables: {
      projectSlug,
    },
    errorPolicy: 'all',
  })
  return data?.project?.livestreams ?? null
}

function getSortBasedOnLiveness(a: Livestream, b: Livestream): number | undefined {
  const aDate = new Date(a.startAt)
  const bDate = new Date(b.startAt)

  if (a.live && !b.live) {
    // live livestreams should go to the front
    return -1
  } else if (!a.live && b.live) {
    // live livestreams should go to the front
    return 1
  } else if (a.live && b.live) {
    // if two livestreams are live at the same time, then sort by date
    // sort streams that will start soonest ahead of other streams
    if (aDate < bDate) return -1
    if (aDate > bDate) return 1
    return 0
  }
}

function getSortBasedOnPastAndFuture(a: Livestream, b: Livestream) {
  const now = new Date()
  const aDate = new Date(a.startAt)
  const bDate = new Date(b.startAt)

  const isLivestreamAFuture = now < aDate
  const isLivestreamBFuture = now < bDate

  // when neither livestream is currently live, then sort by whether the livestream is in the past or the future and then sort by date
  if (isLivestreamAFuture && !isLivestreamBFuture) {
    // sort future livestreams ahead of past livestreams
    return -1
  } else if (!isLivestreamAFuture && isLivestreamBFuture) {
    // sort past livestreams behind future livestreams
    return 1
  } else if (isLivestreamAFuture && isLivestreamBFuture) {
    // both streams are in the future
    // sort streams that will start soonest ahead of other streams
    if (aDate < bDate) return -1
    if (aDate > bDate) return 1
    return 0
  } else {
    // both streams are past
    // sort streams that aired more recently ahead of older streams
    if (aDate < bDate) return 1
    if (aDate > bDate) return -1
    return 0
  }
}

export function sortLivestreams(livestreams: Livestream[] = []): Livestream[] {
  const valid = livestreams?.filter(
    (livestream) => livestream && livestream?.bannerCloudinaryPath && livestream?.name && livestream?.startAt,
  ) as Livestream[] | undefined

  if (!valid) return []

  return valid.sort((a, b) => {
    const sortOrderBasedOnLiveness = getSortBasedOnLiveness(a, b)

    if (typeof sortOrderBasedOnLiveness !== 'undefined') {
      return sortOrderBasedOnLiveness
    }

    return getSortBasedOnPastAndFuture(a, b)
  })
}

export async function getAllProjectLivestreamsSorted(opts: { locale: string }): Promise<Livestream[]> {
  const client = getWebClient({ locale: opts.locale ?? locales.defaultLocale })
  const response = await client.query<GetAllProjectsAndLivestreamsQuery>({
    query: GET_ALL_PROJECTS_AND_LIVESTREAMS,
    errorPolicy: 'all',
  })

  const allLivestreams: Livestream[] =
    response.data.projects
      ?.flatMap((project) => project?.livestreams ?? [])
      .filter(isDefined)
      .map((livestream) => ({
        id: livestream.id ?? 0,
        guid: livestream.guid ?? '',
        name: livestream.name ?? '',
        projectSlug: livestream.projectSlug ?? '',
        isEnded: livestream.isEnded ?? true,
        live: livestream.live ?? false,
        bannerCloudinaryPath: livestream.bannerCloudinaryPath ?? '',
        startAt: livestream.startAt ?? '',
        unavailableReason: livestream.unavailableReason ?? undefined,
      })) ?? []

  return sortLivestreams(allLivestreams)
}
