import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { Image } from '@/atoms/Image'
import { InternalLink } from '@/atoms/InternalLink'
import { LinkViewModel } from '@/services/RenderService'
import { ReactFCC } from '@/types/react'

interface Props extends React.HTMLAttributes<HTMLElement> {
  linkModel: LinkViewModel
  defaultPortrait?: boolean
}

export const PortraitTileCard: ReactFCC<Props> = ({
  children,
  className,
  linkModel,
  defaultPortrait = false,
  ...props
}) => {
  const [isLoadingImage, setLoadingImage] = useState(true)

  const onLoadComplete = useCallback(() => setLoadingImage(false), [])

  return (
    <div {...props}>
      <InternalLink href={linkModel.linkUrl}>
        <div
          className={classNames(
            'relative cursor-pointer overflow-hidden rounded-lg bg-cover duration-[400ms] ease-in-out hover:z-10 md:hover:scale-[1.05]',
            className,
          )}
        >
          <div className={classNames(['relative w-full bg-black rounded-lg', isLoadingImage ? 'animate-pulse' : ''])}>
            <div className={classNames('w-full relative aspect-w-10 aspect-h-16')}>
              {defaultPortrait
                ? linkModel?.portraitUrl && (
                    <Image
                      src={linkModel.portraitUrl}
                      className={classNames(['object-cover rounded-lg absolute top-0 left-0 h-full w-full'])}
                      alt={linkModel.alt as string}
                      onLoadingComplete={onLoadComplete}
                      width={180}
                      height={320}
                    />
                  )
                : linkModel.imageUrl && (
                    <Image
                      src={linkModel.imageUrl}
                      className={classNames(['object-cover rounded-lg absolute top-0 left-0 h-full w-full'])}
                      alt={linkModel.alt as string}
                      onLoadingComplete={onLoadComplete}
                      width={180}
                      height={320}
                    />
                  )}
            </div>
            {children}
          </div>
        </div>
      </InternalLink>
    </div>
  )
}
