import { FC } from 'react'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { GuildScore } from '@/atoms/GuildScore'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { PortraitRowCollection } from '@/molecules/PortraitRowCollection'
import {
  buildLinkTabModel,
  buildLinkTabUrl,
  buildTabSubtitle,
  ComingSoonNode,
  shortenMonthInHeader,
} from '@/services/ComingSoonService'
import { useTranslate } from '@/utils/translate/translate-client'
import { LargePortraitLink } from '../CollectionDisplay/LargePortraitLink'

interface ComingSoonCollectionProps {
  title: string
  ComingSoonTabCollection?: ComingSoonNode[]
  testId?: string
  layout?: 'portrait-grid' | 'portrait-row'
}

export const ComingSoonCollection: FC<ComingSoonCollectionProps> = ({
  title,
  ComingSoonTabCollection,
  testId,
  layout = 'portrait-row',
}) => {
  const { t } = useTranslate('watch')
  const canDisplay = ComingSoonTabCollection && ComingSoonTabCollection?.length > 0

  return (
    <>
      <When condition={canDisplay && layout === 'portrait-row'}>
        <LazyHydrate whenVisible>
          <>
            <PortraitRowCollection testId={testId} title={title} slidesToScroll={6} slidesToShow={6} haltLazyLoad>
              {ComingSoonTabCollection?.map((item) => {
                if (!item) return null

                if (item?.watchable?.__typename !== 'ContentMovie' && item?.watchable?.__typename !== 'ContentSeries') {
                  return null
                }

                const linkModel = buildLinkTabModel(item, true)
                if (!linkModel) return null

                const title = item.title ?? item?.watchable?.name ?? item?.project?.name
                const subTitle = buildTabSubtitle(item, t)

                const header = item.header && shortenMonthInHeader(item.header)

                return (
                  <LazyHydrate key={item?.id} whenVisible>
                    <>
                      <div className="mb-2 mr-4">
                        <LargePortraitLink link={{ ...linkModel, linkUrl: buildLinkTabUrl(item) }} />
                      </div>
                      <TitleAndSubtitleDisplay
                        className="mr-4"
                        title={title || undefined}
                        subtitle={
                          header && item.focus ? `${subTitle} ${header}` : header ? `${subTitle} ${header}` : subTitle
                        }
                      />
                      <When condition={item?.project?.highestScore}>
                        <GuildScore guildScore={item.project.highestScore} />
                      </When>
                    </>
                  </LazyHydrate>
                )
              })}
            </PortraitRowCollection>
          </>
        </LazyHydrate>
      </When>
    </>
  )
}
