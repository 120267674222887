import { FC } from 'react'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { ContentItem } from '@/page/PageContent/ContentItem'
import { ContentExperimentModel, PageDataContext } from '@/services/RenderService'
import { reportWarningToBugsnag } from '@/utils/bugsnag'

interface Props {
  content: ContentExperimentModel
  pageDataContext: PageDataContext
}

export const ContentExperiment: FC<Props> = ({ content, pageDataContext }) => {
  if (!content.experimentId) return null

  const defaultVariants = content.variantsCollection?.items.filter((variant) => variant?.isDefaultVariant)

  if ((defaultVariants?.length ?? 0) > 1) {
    reportWarningToBugsnag(`ContentExperiment: ${content.experimentId} has more than one default variant.`)
  }

  const defaultVariant = defaultVariants?.[0]

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (
    <GBFadeInExperiment
      defaultValue={defaultVariant?.experimentVariantId as any}
      experimentName={content.experimentId as any}
    >
      {content.variantsCollection?.items
        ?.filter((variant) => !variant?.isDefaultVariant)
        .map((variant) => {
          if (!variant?.experimentVariantId || !variant?.contentItemsCollection) return null
          return (
            <ExperimentVariation key={variant.experimentVariantId} variation={variant.experimentVariantId}>
              {variant.contentItemsCollection.items.map((contentItem) => {
                return <ContentItem key={contentItem?.sys.id} content={contentItem} pageDataContext={pageDataContext} />
              })}
            </ExperimentVariation>
          )
        })}

      {defaultVariant && (
        <ExperimentVariation default>
          {defaultVariant?.contentItemsCollection?.items.map((contentItem) => {
            return <ContentItem key={contentItem?.sys.id} content={contentItem} pageDataContext={pageDataContext} />
          })}
        </ExperimentVariation>
      )}
    </GBFadeInExperiment>
  )
}
